import * as RestAPI from 'lib/rest_api'

export const create = (newTeam) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST('/teams', newTeam)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const list = () => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/teams')
      .then((result) => {
        resolve(result.teams)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const setPopulations = (teamId, populations) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/teams/${teamId}/populations`, { populations })
      .then((team) => {
        resolve(team)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const update = (team) => {
  return new Promise((resolve, reject) => {
    RestAPI.PUT(`/teams/${team.id}`, team)
      .then((team) => {
        resolve(team)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
