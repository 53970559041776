import * as usersApi from 'lib/api/users'

export const create = (newUser) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'USERS_CREATE_START' })

    usersApi
      .create(newUser)
      .then((user) => {
        dispatch({ type: 'USERS_CREATE_SUCCESS', user })
      })
      .catch((err) => {
        dispatch({ type: 'USERS_CREATE_FAIL', error: err })
      })
  }
}

export const list = () => {
  // Thunk
  return (dispatch) => {
    dispatch({ type: 'USERS_LIST_START' })

    usersApi
      .list()
      .then((users) => {
        dispatch({ type: 'USERS_LIST_SUCCESS', users })
      })
      .catch((err) => {
        dispatch({ type: 'USERS_LIST_FAIL', error: err })
      })
  }
}

export const setTeam = (userId, teamId) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'USERS_SETTEAM_START' })

    usersApi
      .setTeam(userId, teamId)
      .then((result) => {
        dispatch({ type: 'USERS_SETTEAM_SUCCESS', userId, teamId })
      })
      .catch((err) => {
        dispatch({ type: 'USERS_SETTEAM_FAIL', error: err })
      })
  }
}

export const update = (user) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'USERS_UPDATE_START' })

    usersApi
      .update(user)
      .then((user) => {
        dispatch({ type: 'USERS_UPDATE_SUCCESS', user })
      })
      .catch((err) => {
        dispatch({ type: 'USERS_UPDATE_FAIL', error: err })
      })
  }
}
