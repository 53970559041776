import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '400px',
  },
  title: {
    flex: '0 0 auto',
    margin: '0',
    padding: theme.spacing(2),
  },
  content: {
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}))

export default (props) => {
  const { onClose, onSave, team } = props
  const classes = styles()

  const [name, setName] = React.useState((team && team.name) || '')

  const handleSave = () => {
    onSave({
      name,
    })
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.title}>
        <Typography variant="h5">Team {team ? 'rename' : 'create'}</Typography>
      </div>

      <div className={classes.content}>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          className={classes.input}
        />
      </div>

      <div className={classes.actions}>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>

        <Button
          autoFocus
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </div>
    </form>
  )
}
