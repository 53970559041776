import * as RestAPI from 'lib/rest_api'

export const create = (population) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST('/populations', population)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// list available populations
export const list = () => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/populations')
      .then((result) => {
        resolve(result.populations)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const update = (population) => {
  return new Promise((resolve, reject) => {
    RestAPI.PUT(`/populations/${population.id}`, population)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
