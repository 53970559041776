import auth0 from 'auth0-js'

export const getWebAuth = (redirectUri) => {
  return new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid profile',
    prompt: 'none',
    redirectUri,
  })
}
