import { combineReducers } from 'redux'

const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case 'AUTH_LOGIN_SUCCESS':
      return true
    case 'AUTH_LOGIN_FAIL':
    case 'AUTH_LOGOUT':
      return false
    default:
      return state
  }
}

const populations = (state = {}, action) => {
  switch (action.type) {
    case 'POPS_LIST_SUCCESS':
      if (action.populations) {
        // [{}, {}, ...] => {id: {}, id: {}, ...}
        return (action.populations || []).reduce((acc, val) => {
          acc[val.id] = val
          return acc
        }, {})
      } else {
        return {}
      }
    case 'POPS_CREATE_SUCCESS':
      if (action.population) {
        return {
          ...state,
          [action.population.id]: action.population,
        }
      } else {
        return state
      }
    case 'POPS_UPDATE_SUCCESS':
      if (action.population) {
        return {
          ...state,
          [action.population.id]: action.population,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

const teams = (state = {}, action) => {
  switch (action.type) {
    case 'TEAMS_LIST_SUCCESS':
      if (action.teams) {
        // [{}, {}, ...] => {id: {}, id: {}, ...}
        return (action.teams || []).reduce((acc, val) => {
          acc[val.id] = val
          return acc
        }, {})
      } else {
        return {}
      }
    case 'TEAMS_CREATE_SUCCESS':
      if (action.team) {
        return {
          ...state,
          [action.team.id]: action.team,
        }
      } else {
        return state
      }
    case 'TEAMS_UPDATE_SUCCESS':
      if (action.team) {
        return {
          ...state,
          [action.team.id]: action.team,
        }
      } else {
        return state
      }
    case 'TEAMS_SETPOPULATIONS_SUCCESS':
      if (action.team && state[action.team.id]) {
        return {
          ...state,
          [action.team.id]: action.team,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

const users = (state = {}, action) => {
  switch (action.type) {
    case 'USERS_LIST_SUCCESS':
      if (action.users) {
        // [{}, {}, ...] => {id: {}, id: {}, ...}
        return (action.users || []).reduce((acc, val) => {
          acc[val.user_id] = val
          return acc
        }, {})
      } else {
        return {}
      }
    case 'USERS_CREATE_SUCCESS':
      if (action.user) {
        return {
          ...state,
          [action.user.user_id]: action.user,
        }
      } else {
        return state
      }
    case 'USERS_UPDATE_SUCCESS':
      if (action.user) {
        return {
          ...state,
          [action.user.user_id]: action.user,
        }
      } else {
        return state
      }
    case 'USERS_SETTEAM_SUCCESS':
      if (action.userId && action.teamId && state[action.userId]) {
        const user = state[action.userId]
        return {
          ...state,
          // TODO: don't really love this bit
          [action.userId]: {
            ...user,
            app_metadata: {
              ...user.app_metadata,
              team: action.teamId,
            },
          },
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default combineReducers({
  isAuthenticated,
  populations,
  teams,
  users,
})
