import { createStore, applyMiddleware, compose } from 'redux'

import rootReducer from 'reducers/root_reducer'
import { enhancers, middlewares } from 'store/middleware'

const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers)

const initializeStore = () => {
  const store = createStore(rootReducer, {}, composedEnhancers)
  return store
}

export default initializeStore
