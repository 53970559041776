import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import PeopleIcon from '@material-ui/icons/People'
import Typography from '@material-ui/core/Typography'

export default ({ onEdit, onEditTeam, users }) => (
  <List>
    {users.map((user) => (
      <ListItem key={user.user_id}>
        <ListItemText
          primary={user.email}
          secondary={
            <>
              <Typography component="span" variant="body2">
                Team: {(user.app_metadata && user.app_metadata.team) || 'none'}
              </Typography>
            </>
          }
        />

        <ListItemSecondaryAction>
          <IconButton onClick={() => onEditTeam(user)}>
            <PeopleIcon />
          </IconButton>
          <IconButton onClick={() => onEdit(user)}>
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
)
