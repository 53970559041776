import React from 'react'
import { connect } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import Fab from '@material-ui/core/Fab'

import PopulationForm from 'components/forms/PopulationForm'
import PopulationsList from 'components/lists/PopulationsList'
import * as populationActions from 'actions/populations'

class PopulationsTab extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editPop: null,
      open: false,
    }
  }

  componentDidMount() {
    this.props.onLoad()
  }

  render() {
    const { populations, onCreate, onSave } = this.props
    const { editPop, open } = this.state

    return (
      <div>
        <Fab
          color="secondary"
          onClick={() => this.setState({ open: true })}
          className="fab"
        >
          <AddIcon />
        </Fab>

        <PopulationsList
          populations={populations}
          onEdit={(editPop) => this.setState({ open: true, editPop })}
        />

        <Dialog open={open}>
          <PopulationForm
            population={editPop}
            onClose={() => this.setState({ open: false, editPop: null })}
            onSave={(pop) => (editPop ? onSave(pop) : onCreate(pop))}
          />
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  populations: Object.values(state.populations || {}), // TODO: sorting
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate: (population) => {
    dispatch(populationActions.create(population))
  },
  onLoad: () => {
    dispatch(populationActions.list())
  },
  onSave: (population) => {
    dispatch(populationActions.update(population))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PopulationsTab)
