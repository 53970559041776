import * as RestAPI from 'lib/rest_api'

export const create = (newUser) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST('/users', newUser)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const list = () => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/users')
      .then((result) => {
        resolve(result.users)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const setTeam = (userId, teamId) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/users/${userId}/team`, { team: teamId })
      .then((result) => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const update = (user) => {
  return new Promise((resolve, reject) => {
    RestAPI.PUT(`/users/${user.user_id}`, user)
      .then((user) => {
        resolve(user)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
