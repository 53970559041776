import React from 'react'
import { connect } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import Fab from '@material-ui/core/Fab'

import TeamForm from 'components/forms/TeamForm'
import TeamPopulationsForm from 'components/forms/TeamPopulationsForm'
import TeamsList from 'components/lists/TeamsList'
import * as populationActions from 'actions/populations'
import * as teamActions from 'actions/teams'
import { teamsList } from 'selectors/teams'

function TeamsTab({ onCreate, onLoad, onSave, onSetPopulations, teams }) {
  const [editTeam, setEditTeam] = React.useState(null)
  const [editTeamPopulations, setEditTeamPopulations] = React.useState(null)

  React.useEffect(() => {
    onLoad()
  }, [onLoad])

  const handleSave = (team) => {
    if (editTeam === 'create') {
      onCreate(team)
    } else {
      onSave({ ...editTeam, ...team })
    }
    setEditTeam(null)
  }

  return (
    <div>
      <Fab
        color="secondary"
        onClick={() => setEditTeam('create')}
        className="fab"
      >
        <AddIcon />
      </Fab>

      <TeamsList
        teams={teams}
        onEdit={(editTeam) => setEditTeam(editTeam)}
        onEditPopulations={(team) => setEditTeamPopulations(team)}
      />

      <Dialog open={Boolean(editTeam) || Boolean(editTeamPopulations)}>
        {editTeamPopulations ? (
          <TeamPopulationsForm
            team={editTeamPopulations}
            onClose={() => setEditTeamPopulations(null)}
            onSave={(populations) =>
              onSetPopulations(editTeamPopulations, populations)
            }
          />
        ) : (
          <TeamForm
            team={editTeam === 'create' ? null : editTeam}
            onClose={() => setEditTeam(null)}
            onSave={handleSave}
          />
        )}
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  teams: teamsList(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate: (team) => {
    dispatch(teamActions.create(team))
  },
  onLoad: () => {
    dispatch(populationActions.list())
    dispatch(teamActions.list())
  },
  onSetPopulations: (team, populations) => {
    dispatch(teamActions.setPopulations(team.id, populations))
  },
  onSave: (team) => {
    dispatch(teamActions.update(team))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsTab)
