import { createSelector } from 'reselect'

export const teamsList = createSelector(
  [(state) => state.teams, (state) => state.populations],
  (teams, populations) => {
    if (!teams || !populations) {
      return []
    } else {
      // for each team, convert population ids into population objects
      return Object.values(teams).map((team) => ({
        ...team,
        populations: team.populations.map(
          (popId) => populations[popId] || { id: popId, name: popId }
        ),
      }))
    }
  }
)
