import moment from 'moment'
import React from 'react'
import FulfillIcon from '@material-ui/icons/ThumbUp'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import CancelIcon from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'

function OrdersList({ onCancel, onFulfill, orders }) {
  return (
    <List>
      {orders.map((order) => (
        <ListItem key={order.id}>
          <ListItemText
            primary={`[${order.status}] order for ${order.avatars.length} avatars from ${order.team}`}
            secondary={
              <Typography component="div" variant="caption">
                {moment(order.createdAt).format('LLL')}
              </Typography>
            }
          />

          {order.status === 'NEW' && (
            <ListItemSecondaryAction>
              <IconButton onClick={() => onCancel(order)}>
                <CancelIcon />
              </IconButton>
              <IconButton onClick={() => onFulfill(order)}>
                <FulfillIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default OrdersList
