import React from 'react'
import { connect } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import Fab from '@material-ui/core/Fab'

import UserForm from 'components/forms/UserForm'
import UserTeamForm from 'components/forms/UserTeamForm'
import UsersList from 'components/lists/UsersList'
import * as userActions from 'actions/users'

class UsersTab extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editUser: null,
      editUserTeam: null,
      open: false,
    }
  }

  componentDidMount() {
    this.props.onLoad()
  }

  render() {
    const { onCreate, onSave, onSetTeam, users } = this.props
    const { editUser, editUserTeam, open } = this.state

    return (
      <div>
        <Fab
          color="secondary"
          onClick={() => this.setState({ open: true })}
          className="fab"
        >
          <AddIcon />
        </Fab>

        <UsersList
          users={Object.values(users || {})}
          onEdit={(user) =>
            this.setState({ open: true, editUser: user.user_id })
          }
          onEditTeam={(user) =>
            this.setState({ open: true, editUserTeam: user.user_id })
          }
        />

        <Dialog open={open}>
          {editUserTeam ? (
            <UserTeamForm
              user={users[editUserTeam]}
              onClose={() => this.setState({ open: false, editUserTeam: null })}
              onSave={(team) => onSetTeam(editUserTeam, team)}
            />
          ) : (
            <UserForm
              user={users[editUser]}
              onClose={() => this.setState({ open: false, editUser: null })}
              onSave={(user) =>
                editUser ? onSave(users[editUser], user) : onCreate(user)
              }
            />
          )}
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate: (user) => {
    dispatch(userActions.create(user))
  },
  onLoad: () => {
    dispatch(userActions.list())
  },
  onSetTeam: (userId, teamId) => {
    dispatch(userActions.setTeam(userId, teamId))
  },
  onSave: (origUser, updates) => {
    dispatch(
      userActions.update({
        ...updates,
        user_id: origUser.user_id,
      })
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab)
