import * as SessionApi from 'lib/api/session'
import { getWebAuth } from 'lib/auth'

export const oauth2Login = (authResult) => {
  // NOTE: expiresIn value is in seconds and timestamp is in ms
  const expiration = new Date().getTime() + authResult.expiresIn * 1000

  // TODO: check for nonce and validate it if possible

  // always capture the token into local storage on a login
  window.localStorage.setItem('token', authResult.accessToken)
  window.localStorage.setItem('id_token', authResult.idToken)
  window.localStorage.setItem('expires_at', expiration)

  // THUNK
  return (dispatch) => {
    // grab user profile information immediately after login
    const webAuth = getWebAuth(window.location.href)
    webAuth.client.userInfo(authResult.accessToken, (error, profile) => {
      if (profile) {
        dispatch({
          type: 'AUTH_PROFILE_SUCCESS',
          profile,
        })
      } else {
        dispatch({
          type: 'AUTH_PROFILE_FAIL',
          error,
        })
      }
    })

    // validate the token we received with the backend
    SessionApi.current()
      .then((session) => {
        const { email, name } = session

        window.intercomSettings = {
          ...window.intercomSettings,
          name,
          email,
        }

        dispatch({
          type: 'AUTH_LOGIN_SUCCESS',
          token: authResult.accessToken,
          session,
        })
      })
      .catch((error) => {
        console.log(error)
        // given that our validation failed, lets purge the token we just set
        window.localStorage.clear()

        dispatch({
          type: 'AUTH_LOGIN_FAIL',
          error,
        })
      })
  }
}

export const auth0Logout = () => {
  window.localStorage.clear()

  return {
    type: 'AUTH_LOGOUT',
  }
}
