import * as teamsApi from 'lib/api/teams'

export const create = (newTeam) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'TEAMS_CREATE_START' })

    teamsApi
      .create(newTeam)
      .then((team) => {
        dispatch({ type: 'TEAMS_CREATE_SUCCESS', team })
      })
      .catch((err) => {
        dispatch({ type: 'TEAMS_CREATE_FAIL', error: err })
      })
  }
}

export const list = () => {
  // Thunk
  return (dispatch) => {
    dispatch({ type: 'TEAMS_LIST_START' })

    teamsApi
      .list()
      .then((teams) => {
        dispatch({ type: 'TEAMS_LIST_SUCCESS', teams })
      })
      .catch((err) => {
        dispatch({ type: 'TEAMS_LIST_FAIL', error: err })
      })
  }
}

export const setPopulations = (teamId, populations) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'TEAMS_SETPOPULATIONS_START' })

    teamsApi
      .setPopulations(teamId, populations)
      .then((team) => {
        dispatch({ type: 'TEAMS_SETPOPULATIONS_SUCCESS', team })
      })
      .catch((err) => {
        dispatch({ type: 'TEAMS_SETPOPULATIONS_FAIL', error: err })
      })
  }
}

export const update = (team) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'TEAMS_UPDATE_START' })

    teamsApi
      .update(team)
      .then((team) => {
        dispatch({ type: 'TEAMS_UPDATE_SUCCESS', team })
      })
      .catch((err) => {
        dispatch({ type: 'TEAMS_UPDATE_FAIL', error: err })
      })
  }
}
