import thunk from 'redux-thunk'

const enhancers = []

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

// look for api failures caused by 401 Unauthenticated responses and trigger frontend re-auth when found
const sessionCheck = (store) => (next) => (action) => {
  // const token = localStorage.getItem('token')
  const { error } = action
  const expiration = window.localStorage.getItem('expires_at')

  if (
    (error && (error.status === 401 || error.status === 0)) ||
    (expiration && expiration < Date.now())
  ) {
    window.localStorage.clear()

    window.location = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}&client_id=${process.env.REACT_APP_AUTH0_CLIENTID}`
    store.dispatch({ type: 'AUTH_LOGOUT' })
  }
  return next(action)
}

const middlewares = [thunk, sessionCheck]

// logger must be the last middleware passed into applyMiddleware, otherwise it will log the thunk and any involved promises
if (process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger')
  const logger = createLogger({
    collapsed: true,
  })
  middlewares.push(logger)
}

export { enhancers, middlewares }

/* Custom Middleware Functions

// log any actions which end in FAIL to the console
const actionLogger = store => next => action => {
  if (action && action.type && action.type.endsWith('FAIL')) {
    console.log('action failed', action)
  }

  return next(action)
}

// assign our redux state to window.reduxState after each action is reduced for easy debugging
const reduxStateDebugger = store => next => action => {
  let result = next(action)
  window.reduxState = store.getState()
  return result
}
*/
