import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '600px',
  },
  title: {
    flex: '0 0 auto',
    margin: '0',
    padding: theme.spacing(2),
  },
  content: {
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}))

export default (props) => {
  const { onClose, onSave, population } = props
  const classes = styles()

  const [id, setId] = React.useState((population && population.id) || '')
  const [name, setName] = React.useState((population && population.name) || '')
  const [gender, setGender] = React.useState(
    (population && population.gender) || 'male'
  )
  const [countries, setCountries] = React.useState(
    (population &&
      population.filters.countries &&
      population.filters.countries.join(',')) ||
      ''
  )
  const [states, setStates] = React.useState(
    (population &&
      population.filters.states &&
      population.filters.states.join(',')) ||
      ''
  )
  const [demo, setDemo] = React.useState(
    (population && population.demo) || false
  )

  // NOTE: this is here to handle the label placement on the outlined Select component
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleSave = () => {
    // parse countries, states into arrays of values
    const countriesArr = countries
      .split(',')
      .map((v) => v.trim())
      .filter((v) => v !== '')
    const statesArr = states
      .split(',')
      .map((v) => v.trim())
      .filter((v) => v !== '')

    onSave({
      id,
      name,
      filters: {
        gender,
        countries: countriesArr,
        states: statesArr,
      },
      demo,
    })
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.title}>
        <Typography variant="h5">
          Population {population ? 'edit' : 'create'}
        </Typography>
      </div>

      <div className={classes.content}>
        <TextField
          label="Identifier"
          value={id}
          disabled={population}
          onChange={(e) => setId(e.target.value)}
          variant="outlined"
          className={classes.input}
        />
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          className={classes.input}
        />

        <FormControl variant="outlined" className={classes.input}>
          <InputLabel ref={inputLabel} id="gender">
            Gender
          </InputLabel>
          <Select
            labelId="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            labelWidth={labelWidth}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Countries"
          value={countries}
          onChange={(e) => setCountries(e.target.value)}
          variant="outlined"
          className={classes.input}
          helperText="comma separated. e.g. US,CA,MX"
        />

        <TextField
          label="States"
          value={states}
          onChange={(e) => setStates(e.target.value)}
          variant="outlined"
          className={classes.input}
          helperText="comma separated. e.g. CA,OR,WA"
        />

        <FormControlLabel
          control={
            <Switch
              checked={demo}
              onChange={() => setDemo(!demo)}
              value={demo}
              color="secondary"
            />
          }
          label="Represents demo data"
        />
      </div>

      <div className={classes.actions}>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>

        <Button
          autoFocus
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </div>
    </form>
  )
}
