import * as RestAPI from 'lib/rest_api'

export const list = async () => {
  return RestAPI.GET('/orders')
}

export const setCancelled = async (orderId) => {
  return RestAPI.PUT(`/orders/${orderId}/cancel`)
}

export const setFulfilled = async (orderId) => {
  return RestAPI.PUT(`/orders/${orderId}/fulfill`)
}
