import React from 'react'
import { connect } from 'react-redux'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'

import Homepage from 'components/Homepage'
import * as AuthActions from 'actions/auth'
import { getWebAuth } from 'lib/auth'

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f3f3f5',
    },
    primary: {
      main: '#691b99',
      // main: '#000'
    },
    secondary: {
      main: '#ec407a',
      // main: '#7c55d1'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '8px 36px',
        fontWeight: 600,
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0, // https://material-ui.com/customization/components/#pseudo-classes
        },
        border: '1px #ddd solid',
        borderRadius: '4px !important', // !important to override default of first-child & last-child stylings
        marginBottom: '20px !important',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        borderTop: '1px #ddd solid',
        padding: '24px',
        justifyContent: 'center',
      },
    },
    MuiExpansionPanelActions: {
      root: {
        justifyContent: 'center',
        padding: '24px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '14px',
      },
    },
  },
})

class App extends React.Component {
  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.authenticate()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      // indicates user logout, thus send user to the oauth server for a full logout
      const webAuth = getWebAuth(window.location.origin)
      webAuth.logout()
    }
  }

  authenticate() {
    if (window.location.hash.indexOf('access_token') !== -1) {
      const webAuth = getWebAuth(window.location.href)

      webAuth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = ''
          this.props.oauth2Login(authResult)
        } else if (err) {
          console.log('Error with token parsing', err)
        }
      })
    } else {
      const webAuth = getWebAuth(window.location.origin)

      // set a nonce to associate with the auth
      const nonce =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      window.localStorage.setItem('nonce', nonce)

      webAuth.authorize({
        nonce,
      })
    }
  }

  render() {
    if (!this.props.isAuthenticated) {
      // we don't need to render anything in this situation because 1 of 2 things is happening ..
      //  A) we just got a new oauth2 token and fired a login() action so render() will be called again shortly
      //  B) there is no oauth2 token and the user needs to login, so we are redirecting them to the oauth2 login page
      return null
    } else {
      return (
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Homepage />
          </ThemeProvider>
        </Router>
      )
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: state.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    oauth2Login: (token) => {
      dispatch(AuthActions.oauth2Login(token))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
