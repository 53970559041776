import * as populationsApi from 'lib/api/populations'

export const create = (newPopulation) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'POPS_CREATE_START' })

    populationsApi
      .create(newPopulation)
      .then((population) => {
        dispatch({ type: 'POPS_CREATE_SUCCESS', population })
      })
      .catch((err) => {
        dispatch({ type: 'POPS_CREATE_FAIL', error: err })
      })
  }
}

export const list = () => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'POPS_LIST_START' })

    populationsApi
      .list()
      .then((pops) => {
        dispatch({ type: 'POPS_LIST_SUCCESS', populations: pops })
      })
      .catch((err) => {
        dispatch({ type: 'POPS_LIST_FAIL', error: err })
      })
  }
}

export const update = (population) => {
  // THUNK
  return (dispatch) => {
    dispatch({ type: 'POPS_UPDATE_START' })

    populationsApi
      .update(population)
      .then((population) => {
        dispatch({ type: 'POPS_UPDATE_SUCCESS', population })
      })
      .catch((err) => {
        dispatch({ type: 'POPS_UPDATE_FAIL', error: err })
      })
  }
}
