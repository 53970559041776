import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import EditPopsIcon from '@material-ui/icons/Rowing'

export default ({ onEdit, onEditPopulations, teams }) => (
  <List>
    {teams.map((team) => (
      <ListItem key={team.id}>
        <ListItemText
          primary={team.name}
          secondary={team.populations.map((t) => t.name).join(',')}
        />

        <ListItemSecondaryAction>
          <IconButton onClick={() => onEditPopulations(team)}>
            <EditPopsIcon />
          </IconButton>
          <IconButton onClick={() => onEdit(team)}>
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
)
