import React from 'react'
import { makeStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import OrdersTab from 'components/OrdersTab'
import PopulationsTab from 'components/PopulationsTab'
import TeamsTab from 'components/TeamsTab'
import UsersTab from 'components/UsersTab'

const styles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
  },
}))

const Homepage = (props) => {
  const classes = styles()

  const [tab, setTab] = React.useState(0)

  return (
    <div>
      <AppBar position="static">
        <Tabs centered value={tab} onChange={(e, val) => setTab(val)}>
          <Tab label="Populations" />
          <Tab label="Teams" />
          <Tab label="Users" />
          <Tab label="Orders" />
        </Tabs>
      </AppBar>

      <Grid container justify="center" className={classes.container}>
        <Grid item xs={6}>
          {tab === 0 ? <PopulationsTab /> : null}
          {tab === 1 ? <TeamsTab /> : null}
          {tab === 2 ? <UsersTab /> : null}
          {tab === 3 ? <OrdersTab /> : null}
        </Grid>
      </Grid>
    </div>
  )
}

export default Homepage
