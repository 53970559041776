import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '600px',
  },
  title: {
    flex: '0 0 auto',
    margin: '0',
    padding: theme.spacing(2),
  },
  content: {
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}))

export default (props) => {
  const { onClose, onSave, user } = props
  const classes = styles()

  const propsTeam = user.app_metadata && user.app_metadata.team
  const [team, setTeam] = React.useState(propsTeam || '')
  // const [origTeam, setOrigTeam] = React.useState(team)
  // console.log(team, origTeam, propsTeam)
  // if (propsTeam !== origTeam) {
  //   console.log('team updated to', propsTeam)
  // }

  const handleSave = () => {
    onSave(team)
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.title}>
        <Typography variant="h5">Assign team</Typography>
      </div>

      <div className={classes.content}>
        <TextField
          label="Team"
          value={team}
          onChange={(e) => setTeam(e.target.value)}
          variant="outlined"
          className={classes.input}
        />
      </div>

      <div className={classes.actions}>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>

        <Button
          autoFocus
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </div>
    </form>
  )
}
