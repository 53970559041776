import React from 'react'

import OrdersList from 'components/lists/OrdersList'
import * as OrdersApi from 'lib/api/orders'

function OrdersTab() {
  const [orders, setOrders] = React.useState(null)

  React.useEffect(() => {
    if (!orders) {
      OrdersApi.list()
        .then((orders) => {
          setOrders(orders.orders)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [orders])

  const handleCancelOrder = (order) => {
    OrdersApi.setCancelled(order.id)
      .then((order) => {
        // replace old order with new one
        const rest = orders.filter((o) => o.id !== order.id)
        setOrders([order, ...rest])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleFulfillOrder = (order) => {
    OrdersApi.setFulfilled(order.id)
      .then((order) => {
        // replace old order with new one
        const rest = orders.filter((o) => o.id !== order.id)
        setOrders([order, ...rest])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div>
      <OrdersList
        onCancel={handleCancelOrder}
        onFulfill={handleFulfillOrder}
        orders={Object.values(orders || {})}
      />
    </div>
  )
}

export default OrdersTab
