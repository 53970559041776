import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

export default ({ populations, onEdit }) => (
  <List>
    {populations.map((population) => (
      <ListItem key={population.id}>
        <ListItemText
          primary={
            population.demo ? `[demo] ${population.name}` : population.name
          }
          secondary={
            <>
              <Typography component="div" variant="body2">
                Gender: {population.filters.gender || 'all'}
              </Typography>
              <Typography component="div" variant="body2">
                Countries:{' '}
                {(population.filters.countries || []).join(',') || 'all'}
              </Typography>
              <Typography component="div" variant="body2">
                States: {(population.filters.states || []).join(',') || 'all'}
              </Typography>
            </>
          }
        />

        <ListItemSecondaryAction>
          <IconButton onClick={() => onEdit(population)}>
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
)
